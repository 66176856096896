import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {provideRouter, withViewTransitions} from '@angular/router';

import { routes } from './app.routes';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {NzModalService} from "ng-zorro-antd/modal";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {QuillModule} from "ngx-quill";
import {CodemirrorModule} from "@ctrl/ngx-codemirror";
import {httpInterceptor} from "@shared/interceptors/http.interceptor";

registerLocaleData(en);

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withViewTransitions()),
    provideNzI18n(en_US),
    importProvidersFrom(FormsModule),
    provideAnimationsAsync(),
    importProvidersFrom(CodemirrorModule),
    provideHttpClient(withInterceptors([httpInterceptor])),
    provideHttpClient(),
    ReactiveFormsModule,
    NzModalService,
    NzNotificationService,
    importProvidersFrom(QuillModule.forRoot())
  ]
};
