import {HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import { inject } from '@angular/core';
import {LocalStorageService} from "@features/auth/services/local-storage.service";
import {Router} from "@angular/router";
import {catchError, throwError} from "rxjs";

export const httpInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn) => {
  const storageService: LocalStorageService = inject(LocalStorageService);
  const router: Router = inject(Router);

  const token: string | null = storageService.getAccessToken();

  if(token) {
    request = request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token)
    });
    request = request.clone({
      headers: request.headers.set('Accept', 'application/json')
    });

  }

  return next(request).pipe(
    catchError(err => {
      if(err.status == 401) {
        storageService.clearStorage();
        router.navigate(['/auth/login']);
      }
      return throwError(() => err);
    })
  );
};
